import React from 'react';
import resumeFile from '../documents/resume.pdf';
const AboutUs = ({ classicHeader, darkTheme }) => {
    return (
        <section id='about' className={'section ' + (darkTheme ? 'bg-dark-1' : '')}>
            <div className={'container ' + (classicHeader ? '' : 'px-lg-5')}>
                {/* Heading */}
                <div className='position-relative d-flex text-center mb-5'>
                    <h2
                        className={
                            'text-24  text-uppercase fw-600 w-100 mb-0 ' +
                            (darkTheme ? 'text-muted opacity-1' : 'text-light opacity-4')
                        }
                    >
                        About Me
                    </h2>
                    <p
                        className={
                            'text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 ' +
                            (darkTheme ? 'text-white' : 'text-dark')
                        }
                    >
                        Know Me More
                        <span className='heading-separator-line border-bottom border-3 border-primary d-block mx-auto' />
                    </p>
                </div>
                {/* Heading end*/}
                <div className='row gy-5'>
                    {/* About me content start */}
                    <div className='col-lg-7 col-xl-8 text-center text-lg-start'>
                        <h2 className={'text-7 fw-600 mb-3 ' + (darkTheme ? 'text-white' : '')}>
                            I'm <span className='text-primary'>Tim Turner,</span> a Product Manager and Software
                            Engineer
                        </h2>
                        <p className={darkTheme ? 'text-white-50' : ''}>
                            I'm a Product Manager and Software Engineer based in Sunny South Florida. Since the time of
                            the World Wide Web becoming a reality I have had a passion to develop things that have a
                            purpose and solve real world problems. I specialize in building and managing custom SaaS
                            solution software from adult education management systems to pharmacovigilance saftey
                            monitoring.
                        </p>
                        <p className={darkTheme ? 'text-white-50' : ''}>
                            Over the years I have moved into product management, in addition to staying true to my love
                            of software development. I have enjoyed this transition as it allows me the opportunity to
                            design and shape the future of products I have worked on.
                        </p>
                        <p className={darkTheme ? 'text-white-50' : ''}>
                            I have had the fortune to work with large companies in Transportation Management,
                            Telecommunications, Mobile and PC Gaming, as well as top 5 Pharmaceutical companies.
                        </p>
                        <p className={darkTheme ? 'text-white-50' : ''}>
                            When I am not at the keys, you will find me traveling with my beautiful wife and our amazing
                            German Shepard, cooking, or playing golf.
                        </p>
                    </div>
                    {/* About me content end */}
                    {/* about me personal detials start */}
                    <div className='col-lg-5 col-xl-4'>
                        <div className='ps-lg-4'>
                            <ul className={'list-style-2 ' + (darkTheme ? 'list-style-light text-light' : '')}>
                                <li>
                                    <span className='fw-600 me-2'>Name:</span>Tim Turner
                                </li>
                                <li>
                                    <span className='fw-600 me-2'>Email:</span>
                                    <a href='mailto:tnjturner@gmail.com'>tnjturner@gmail.com</a>
                                </li>
                                <li>
                                    <span className='fw-600 me-2'>Age:</span>52
                                </li>
                                <li className='border-0'>
                                    <span className='fw-600 me-2'>From:</span>Port Saint Lucie, Florida
                                </li>
                            </ul>
                            <a href={resumeFile} download className='btn btn-primary rounded-pill'>
                                Download CV
                            </a>
                        </div>
                    </div>
                    {/* about me personal details end */}
                </div>
                {/* projects rewards counting start */}
                <div className={'brands-grid separator-border mt-5 ' + (darkTheme ? 'separator-border-light' : '')}>
                    <div className='row'>
                        <div className='col-6 col-md-3'>
                            <div className='featured-box text-center'>
                                <h4 className={'text-12  mb-0 ' + (darkTheme ? 'text-white-50' : 'text-muted')}>
                                    <span>20</span>+
                                </h4>
                                <p className={'mb-0 ' + (darkTheme ? 'text-light' : '')}>Years Experiance</p>
                            </div>
                        </div>
                        <div className='col-6 col-md-3'>
                            <div className='featured-box text-center'>
                                <h4 className={'text-12  mb-0 ' + (darkTheme ? 'text-white-50' : 'text-muted')}>
                                    <span>90</span>+
                                </h4>
                                <p className={'mb-0 ' + (darkTheme ? 'text-light' : '')}>Happy Clients</p>
                            </div>
                        </div>
                        <div className='col-6 col-md-3'>
                            <div className='featured-box text-center'>
                                <h4 className={'text-12  mb-0 ' + (darkTheme ? 'text-white-50' : 'text-muted')}>
                                    <span>300</span>+
                                </h4>
                                <p className={'mb-0 ' + (darkTheme ? 'text-light' : '')}>Projects Done</p>
                            </div>
                        </div>
                        <div className='col-6 col-md-3'>
                            <div className='featured-box text-center'>
                                <h4 className={'text-12  mb-0 ' + (darkTheme ? 'text-white-50' : 'text-muted')}>
                                    <span>10</span>+
                                </h4>
                                <p className={'mb-0 ' + (darkTheme ? 'text-light' : '')}>Awards Received</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* projects rewards counting end */}
            </div>
        </section>
    );
};

export default AboutUs;
