/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import Isotope from 'isotope-layout';
import ProjectDetailsModal from './ProjectDetailsModal';
const Portfolio = ({ classicHeader, darkTheme }) => {
    // init one ref to store the future isotope object
    const isotope = useRef();
    // store the filter keyword in a state
    const [filterKey, setFilterKey] = useState('*');
    const [imagesLoaded, setimagesLoaded] = useState(0);
    const [selectedProjectDetails, setSelectedProjectDetails] = useState();

    const filters = {
        PHARMA: 'Pharmacovigilance',
        AE: 'Adult Education',
        OTHER: 'Other Projects',
    };

    const projectsData = [
        {
            title: 'Pharmacovigilance Monitoring',
            projectInfo:
                'The business and product groups maintain an online application for monitoring Individual Case Safety Report (ICSR) compliance.  This includes the ongoing client management, support and maintenance, new sales opportunities, and implementation of new enhancements for the system and the processes needed for monitoring ICSR compliance and the customized ETL (Extract, Transform, Load) program to load the appropriate safety data into the compliance monitoring application',
            client: 'IQVIA',
            technologies: 'HTML5, C#, CSS, JavaScript, XML',
            industry: 'Safety / Pharmacovigilance',
            date: 'August 16, 2021',
            url: {
                name: 'www.iqvia.com',
                link: 'https://www.iqvia.com/solutions/integrated-global-compliance/safety-and-pharmacovigilance',
            },
            thumbImage: 'images/projects/project-1.jpg',
            sliderImages: ['images/projects/project-5.jpg'],
            categories: [filters.PHARMA],
        },
        {
            title: 'ADVANYS',
            projectInfo: `ADVANSYS was developed to provide greater accountability and improve performance in Adult Education systems at the state and local levels. Administrators at every level have immediate, accurate access to program data and reports, allowing them to more effectively manage the programs under their jurisdiction.`,
            client: 'Benchmark ITS',
            technologies: 'HTML5, C#, CSS, JavaScript, Angular, WebAPI',
            industry: 'Adult Education',
            date: 'June 1, 2022',
            url: {
                name: 'www.benchmarkits.com',
                link: 'https://www.benchmarkits.com',
            },
            thumbImage: 'images/projects/project-3.jpg',
            sliderImages: ['images/projects/project-6.jpg', 'images/projects/project-4.jpg'],
            categories: [filters.AE],
        },
        {
            title: 'EMEA Data Triaging',
            projectInfo:
                'The business and product groups maintain an easy to use automates the triaging of E3 data files from the EMEA. It seamlessly integrates with current Argus setup and runs in an unattended mode requiring no manual intervention. This includes the ongoing client management, support and maintenance, new sales opportunities, and implementation of new enhancements for the system and the processes needed for current and future regulation changes for submissions.',
            client: 'IQVIA',
            technologies: 'HTML5, C#, CSS, JavaScript, XML, Java',
            industry: 'Safety / Pharmacovigilance',
            date: 'January, 30, 2023',
            url: {
                name: 'www.iqvia.com',
                link: 'https://www.iqvia.com/solutions/integrated-global-compliance/safety-and-pharmacovigilance',
            },
            thumbImage: 'images/projects/project-2.png',
            sliderImages: ['images/projects/project-1.jpg'],
            categories: [filters.PHARMA],
        },
        {
            title: 'Reset Generation Website',
            projectInfo:
                'Reset Generation (formerly known under the code name Project White Rock) is a cross-platform action-puzzle video game by RedLynx for Windows and N-Gage 2.0 compatible devices. Soundtrack for the game was composed by 8 Bit Weapon. Reset Generation includes ten archetypal characters easily recognizable by older players who grew up with the NES and Mega Drive/Genesis, with some of the more easily recognizable characters being a hedgehog, plumber, monster trainer and a level 50 elf. Apart from having 4-player matches, the game also offers an 18 mission-based single-player campaign.  Themis Media developed the website and promotial material leading up to the release of the game.',
            client: 'Themis Media (client: Nokia)',
            technologies: 'Java, JBoss, HTML, CSS, Flash',
            industry: 'Mobile/PC Gaming',
            date: '	August 4, 2008',
            url: {
                name: 'resetgeneration-site.arena.n-gage.com (Archived)',
                link: 'https://en.wikipedia.org/wiki/Reset_Generation',
            },

            thumbImage: 'images/projects/project-6-0.jpg',
            sliderImages: [
                'images/projects/project-6.png',
                'images/projects/project-6-1.png',
                'images/projects/project-6-2.jpg',
            ],
            categories: [filters.AE],
        },
        {
            title: 'HSEAdvantage/MyHSE',
            projectInfo:
                'Self serve or in‐house document fulfillment including: diplomas, transcripts and other documentation. Features automatic data match with ADVANSYS',
            client: 'Benchmark ITS',
            technologies: 'HTML5, C#, CSS, JavaScript, Angular, WebAPI',
            industry: 'Adult Education',
            date: 'June 1, 2022',
            url: {
                name: 'www.benchmarkits.com',
                link: 'https://www.benchmarkits.com',
            },
            thumbImage: 'images/projects/project-3.jpg',
            sliderImages: ['images/projects/project-6.jpg', 'images/projects/project-4.jpg'],
            categories: [filters.AE],
        },

        {
            title: 'ContinuED',
            projectInfo:
                'Expanding community outreach plays a significant role in increasing revenue and keeping current and potential students actively engaged. ContinuED from Benchmark ITS provides a powerful tool for promoting, managing, and monitoring classes, meetings and seminars outside the traditional academic offerings.',
            client: 'Benchmark ITS',
            technologies: 'HTML5, C#, CSS, JavaScript, Angular, WebAPI',
            industry: 'Adult Education',
            date: 'June 1, 2022',
            url: {
                name: 'www.benchmarkits.com',
                link: 'https://www.benchmarkits.com',
            },
            thumbImage: 'images/projects/project-6.jpg',
            sliderImages: ['images/projects/project-3.jpg', 'images/projects/project-4.jpg'],
            categories: [filters.OTHER],
        },

        {
            title: 'Horse Club',
            projectInfo:
                'HORSE CLUB is brought to Facebook by Themis Group, the pioneers in online gaming responsible for the Beowulf the Movie Facebook game and the AEIM/M16 Award Winning Heroes Mini social game for the Heroes of Might & Magic franchise. The concept of HORSE CLUB emerged from Themis Group’s 20 percent policy, which asks all employees to spend 20 percent of their time on innovative projects outside the scope of their regular duties.',
            client: 'Themis Group (Media)',
            technologies: 'HTML5, CSS3, PHP',
            industry: 'Social Media Gaming',
            date: 'December 8, 2010',
            url: {
                name: 'www.example.com',
                link: 'https://www.escapistmagazine.com/themis-group-releases-the-first-horse-competition-game-for-facebook/',
            },
            thumbImage: 'images/projects/project-7.jpg',
            sliderImages: ['images/projects/project-1.jpg', 'images/projects/project-5.jpg'],
            categories: [filters.OTHER],
        },
    ];

    // initialize an Isotope object with configs
    useEffect(() => {
        isotope.current = new Isotope('.portfolio-filter', {
            itemSelector: '.filter-item',
            layoutMode: 'masonry',
        });

        // cleanup
        return () => {
            isotope.current.destroy();
        };
    }, []);

    // handling filter key change
    useEffect(() => {
        if (imagesLoaded) {
            filterKey === '*'
                ? isotope.current.arrange({ filter: `*` })
                : isotope.current.arrange({ filter: `.${filterKey}` });
        }
    }, [filterKey, imagesLoaded]);

    const handleFilterKeyChange = (key) => () => setFilterKey(key);

    return (
        <>
            <section id='portfolio' className={'section ' + (darkTheme ? 'bg-dark-2' : 'bg-light')}>
                <div className={'container ' + (classicHeader ? '' : 'px-lg-5')}>
                    {/* Heading */}
                    <div className='position-relative d-flex text-center mb-5'>
                        <h2
                            className={
                                'text-24  text-uppercase fw-600 w-100 mb-0 ' +
                                (darkTheme ? 'text-muted opacity-1' : 'text-light opacity-4')
                            }
                        >
                            Portfolio
                        </h2>
                        <p
                            className={
                                'text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 ' +
                                (darkTheme ? 'text-white' : 'text-dark')
                            }
                        >
                            {' '}
                            Sample of my Work
                            <span className='heading-separator-line border-bottom border-3 border-primary d-block mx-auto' />
                        </p>
                    </div>
                    {/* Heading end*/}
                    {/* Filter Menu */}
                    <ul
                        className={
                            'portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 ' +
                            (darkTheme ? 'nav-light' : '')
                        }
                    >
                        <li className='nav-item'>
                            <button
                                className={'nav-link ' + (filterKey === '*' ? 'active' : '')}
                                onClick={handleFilterKeyChange('*')}
                            >
                                All
                            </button>
                        </li>
                        {Object.keys(filters).map((oneKey, i) => (
                            <li className='nav-item' key={i}>
                                <button
                                    className={'nav-link ' + (filterKey === filters[oneKey] ? 'active' : '')}
                                    onClick={handleFilterKeyChange(filters[oneKey])}
                                >
                                    {filters[oneKey]}
                                </button>
                            </li>
                        ))}
                    </ul>
                    {/* Filter Menu end */}
                    <div className='portfolio popup-ajax-gallery'>
                        <div className='row portfolio-filter filter-container g-4'>
                            {projectsData.length > 0 &&
                                projectsData.map((project, index) => (
                                    <div
                                        className={'col-sm-6 col-lg-4 filter-item ' + project.categories.join(' ')}
                                        key={index}
                                    >
                                        <div className='portfolio-box rounded'>
                                            <div className='portfolio-img rounded'>
                                                <img
                                                    onLoad={() => {
                                                        setimagesLoaded(imagesLoaded + 1);
                                                    }}
                                                    className='img-fluid d-block portfolio-image'
                                                    src={project.thumbImage}
                                                    alt=''
                                                />
                                                <div className='portfolio-overlay'>
                                                    <a
                                                        className='popup-ajax stretched-link'
                                                        href=''
                                                        onClick={() => {
                                                            setSelectedProjectDetails(projectsData[index]);
                                                        }}
                                                        data-bs-toggle='modal'
                                                        data-bs-target='#exampleModal'
                                                    >
                                                        &nbsp;
                                                    </a>
                                                    <div className='portfolio-overlay-details'>
                                                        <h5 className='text-white fw-400'>{project.title}</h5>
                                                        <span className='text-light'>{project.categories}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </section>
            <div className='project-details-modal'>
                {/* Modal */}
                <ProjectDetailsModal
                    projectDetails={selectedProjectDetails}
                    darkTheme={darkTheme}
                ></ProjectDetailsModal>
            </div>
        </>
    );
};

export default Portfolio;
