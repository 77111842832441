import React from 'react';
import resumeFile from '../documents/resume.pdf';

const Resume = ({ classicHeader, darkTheme }) => {
    const educationDetails = [
        {
            yearRange: '2003 - 2007',
            title: 'Bachelor Degree',
            place: 'University of Phoenix',
            desc: 'Bachelor’s Degree, Business/e-Business',
        },
        {
            yearRange: '2008 - 2010',
            title: 'Master Degree',
            place: 'University of Phoenix',
            desc: 'Master’s Degree, Information Systems',
        },
    ];

    const experienceDetails = [
        {
            yearRange: '2014 - Current',
            title: 'Associate Director, Product Manager',
            place: 'IQVIA',
            desc: 'Product and system management of multiple products including ICSR safety compliance and triaging of ICSR submissions for multiple top 50 global based pharmaceutical companies.',
        },
        {
            yearRange: '2011 - Current',
            title: 'Director of Software Development and DevOps',
            place: 'Benchmark ITS, LLC',
            desc: 'Responsible for managing the development and technical efforts for the Adult Education and HSE Transcript Management cloud-based Software as a Service (SaaS) applications.',
        },
        {
            yearRange: '2006 - 2011',
            title: 'Senior Web Developer',
            place: 'Themis Media',
            desc: 'Developed multi-tenant Custom Content Management System (CMS) for high-traffic online gaming news sites and special marketing projects for clients.',
        },
        {
            yearRange: '2000 - 2006',
            title: 'Product Development Engineer III',
            place: 'Transcore/DAT Solutions',
            desc: 'Development and maintenance of custom freight matching software originally developed as a FoxPro application and later transitioned into a ColdFusion web application and finally into a ASP.NET web application.',
        },
    ];

    const skills = [
        {
            name: 'Product Design & Management',
            percent: 100,
        },
        {
            name: 'Project Management',
            percent: 90,
        },
        {
            name: 'Software Development Life Cycle (SDLC)',
            percent: 100,
        },
        {
            name: 'Communication',
            percent: 98,
        },
        {
            name: 'Leadership',
            percent: 98,
        },
        {
            name: 'C# (WebForms/MVC)',
            percent: 90,
        },
        {
            name: 'HTML/CSS',
            percent: 95,
        },
        {
            name: 'JavaScript',
            percent: 95,
        },
        {
            name: 'React.JS',
            percent: 80,
        },
        {
            name: 'Angular',
            percent: 84,
        },
        {
            name: 'WebAPI',
            percent: 95,
        },
        {
            name: 'SQL (MSSQL, PL/SQL)',
            percent: 95,
        },
        {
            name: 'DevOps (Azure, CI/CD)',
            percent: 98,
        },
        {
            name: 'Cooking',
            percent: 95,
        },
        {
            name: 'Golf',
            percent: 50,
        },
    ];

    return (
        <section id='resume' className={'section ' + (darkTheme ? 'bg-dark-1' : '')}>
            <div className={'container ' + (classicHeader ? '' : 'px-lg-5')}>
                {/* Heading */}
                <div className='position-relative d-flex text-center mb-5'>
                    <h2
                        className={
                            'text-24  text-uppercase fw-600 w-100 mb-0 ' +
                            (darkTheme ? 'text-muted opacity-1' : 'text-light opacity-4')
                        }
                    >
                        Summary
                    </h2>
                    <p
                        className={
                            'text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 ' +
                            (darkTheme ? 'text-white' : 'text-dark')
                        }
                    >
                        {' '}
                        Resume
                        <span className='heading-separator-line border-bottom border-3 border-primary d-block mx-auto' />
                    </p>
                </div>
                {/* Heading end*/}
                <div className='row gx-5'>
                    {/* My Education */}
                    <div className='col-md-6'>
                        <h2 className={'text-6 fw-600 mb-4 ' + (darkTheme ? 'text-white' : '')}>My Education</h2>
                        {educationDetails.length > 0 &&
                            educationDetails.map((value, index) => (
                                <div
                                    key={index}
                                    className={
                                        'bg-white  rounded p-4 mb-4 ' + (darkTheme ? 'bg-dark' : 'bg-white border')
                                    }
                                >
                                    <p className='badge bg-primary text-2 fw-400'>{value.yearRange}</p>
                                    <h3 className={'text-5 ' + (darkTheme ? 'text-white' : '')}>{value.title}</h3>
                                    <p className={darkTheme ? 'text-primary' : 'text-danger'}>{value.place}</p>
                                    <p className={'mb-0 ' + (darkTheme ? 'text-white-50' : '')}>{value.desc}</p>
                                </div>
                            ))}
                    </div>
                    {/* My Experience */}
                    <div className='col-md-6'>
                        <h2 className={'text-6 fw-600 mb-4 ' + (darkTheme ? 'text-white' : '')}>My Experience</h2>
                        {experienceDetails.length > 0 &&
                            experienceDetails.map((value, index) => (
                                <div
                                    key={index}
                                    className={
                                        'bg-white  rounded p-4 mb-4 ' + (darkTheme ? 'bg-dark' : 'bg-white border')
                                    }
                                >
                                    <p className='badge bg-primary text-2 fw-400'>{value.yearRange}</p>
                                    <h3 className={'text-5 ' + (darkTheme ? 'text-white' : '')}>{value.title}</h3>
                                    <p className={darkTheme ? 'text-primary' : 'text-danger'}>{value.place}</p>
                                    <p className={'mb-0 ' + (darkTheme ? 'text-white-50' : '')}>{value.desc}</p>
                                </div>
                            ))}
                    </div>
                </div>
                {/* My Skills */}
                <h2 className={'text-6 fw-600 mt-4 mb-4 ' + (darkTheme ? 'text-white' : '')}>My Skills</h2>
                <div className='row gx-5'>
                    {skills.length > 0 &&
                        skills.map((skill, index) => (
                            <div key={index} className='col-md-6'>
                                <p className={' fw-500 text-start mb-2 ' + (darkTheme ? 'text-light' : 'text-dark')}>
                                    {skill.name} <span className='float-end'>{skill.percent}%</span>
                                </p>
                                <div className={'progress progress-sm mb-4 ' + (darkTheme ? 'bg-dark' : '')}>
                                    <div
                                        className='progress-bar'
                                        role='progressbar'
                                        style={{ width: skill.percent + '%' }}
                                        aria-valuenow={skill.percent}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                    />
                                </div>
                            </div>
                        ))}
                </div>
                <div className='text-center mt-5'>
                    <a className='btn btn-outline-secondary rounded-pill shadow-none' href={resumeFile} download>
                        Download CV
                        <span className='ms-1'>
                            <i className='fas fa-download' />
                        </span>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Resume;
