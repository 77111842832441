import React from 'react';

import Slider from 'react-slick';

const Testimonials = ({ classicHeader, darkTheme }) => {
    const reviews = [
        {
            name: 'Jason Smith',
            position: 'Sr Software Development Engineer at Amazon Games',
            src: 'images/testimonial/client-sm-1.jpg',
            desc: `Tim has always been my go-to developer for anything critical, unusual, or wildly outside our company's normal areas of expertise. Regardless of what the project is or the difficulties encountered on the way, especially the client-inflicted ones, Tim always manages to pull everything together in the end.`,
            rating: 5,
        },
        {
            name: 'Jon Hayter',
            position: 'UX Lead at Deep Silver Volition',
            src: 'images/testimonial/client-sm-2.jpg',
            desc: `Tim's ability to take any creative concept and find a way to translate it onto a technical platform is excellent. I never had to compromise anything, as he always found a way to make it work. He is a pleasure to work with, and will eagerly find a way around any obstacle.`,
            rating: 5,
        },
        {
            name: 'Alexander Macris',
            position: 'Studio Head, Autarch LLC',
            src: 'images/testimonial/client-sm-3.jpg',
            desc: `Of all the software developers I've worked with, Tim Turner had the broadest range of experience and flexibility. He is able to just pick up any programming language and code with it, an impressive ability that enabled us to green-light many projects we couldn't otherwise tackle. Tim delivers this flexibility and skill with a friendly demeanor and team-oriented attitude that makes him an excellent colleague as well as a great mentor for junior coders. I recommend him highly!Of all the software developers I've worked with, Tim Turner had the broadest range of experience and flexibility. He is able to just pick up any programming language and code with it, an impressive ability that enabled us to green-light many projects we couldn't otherwise tackle. Tim delivers this flexibility and skill with a friendly demeanor and team-oriented attitude that makes him an excellent colleague as well as a great mentor for junior coders. I recommend him highly!`,
            rating: 5,
        },
    ];

    var settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
    };
    return (
        <section id='testimonial' className={'section ' + (darkTheme ? 'bg-dark-1' : '')}>
            <div className={'container ' + (classicHeader ? '' : 'px-lg-5')}>
                {/* Heading */}
                <div className='position-relative d-flex text-center mb-5'>
                    <h2
                        className={
                            'text-24  text-uppercase fw-600 w-100 mb-0 ' +
                            (darkTheme ? 'text-muted opacity-1' : 'text-light opacity-4')
                        }
                    >
                        Testimonial
                    </h2>
                    <p
                        className={
                            'text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 ' +
                            (darkTheme ? 'text-white' : 'text-dark')
                        }
                    >
                        {' '}
                        Nice things people have said
                        <span className='heading-separator-line border-bottom border-3 border-primary d-block mx-auto' />
                    </p>
                </div>
                {/* Heading end*/}

                <Slider {...settings}>
                    {reviews.length > 0 &&
                        reviews.map((value, index) => (
                            <div className='item' key={index}>
                                <div className={' rounded p-5 ' + (darkTheme ? 'bg-dark' : 'bg-light')}>
                                    <div className='d-flex align-items-center mt-auto mb-4'>
                                        <img
                                            className='img-fluid rounded-circle border d-inline-block w-auto'
                                            src={value.src}
                                            alt=''
                                        />
                                        <p className='ms-3 mb-0'>
                                            <strong
                                                className={'d-block fw-600 ' + (darkTheme ? 'text-white' : 'text-dark')}
                                            >
                                                {value.name}
                                            </strong>
                                            <span className='text-muted fw-500'> {value.position} </span>
                                        </p>
                                    </div>
                                    <p className={' fw-500 mb-4 ' + (darkTheme ? 'text-white' : 'text-dark')}>
                                        “{value.desc}”
                                    </p>
                                    <span className='text-2'>
                                        {[...Array(value.rating)].map((value, i) => (
                                            <i className='fas fa-star text-warning' key={i} />
                                        ))}
                                    </span>
                                </div>
                            </div>
                        ))}
                </Slider>
            </div>
        </section>
    );
};

export default Testimonials;
