import React from 'react';

const Services = ({ classicHeader, darkTheme }) => {
    // services details
    const services = [
        {
            name: 'Product Management',
            desc: 'Working closely with developers, stakeholders, and clients I identify the needs and create a marketing strategy, product roadmap, and finally a product that is successful.',
            icon: 'fas fa-diagram-project',
        },
        {
            name: 'Software Development',
            desc: 'Whether it is back-end or front-end development, I have worked on it all. Specializing in C#, Angular 2+, React.js, Microsoft and Oracle SQL Databases.',
            icon: 'fas fa-desktop',
        },
        {
            name: 'Project Management',
            desc: 'Working with stakeholders, the product manager, and developers I plan and organize resources using established principles, procedures, policies, and skills to deliver an outcome within budget and time constraints to produce a solid deliverable within a set timeframe.',
            icon: 'fas fa-pencil-ruler',
        },
        {
            name: 'Product Design',
            desc: 'From staying up to date with Federal guidelines or the ever changing pharmaceutical safety requirements, I take these requirements as well as identifying marking opportunities to create or improve products for clients.',
            icon: 'fas fa-paint-brush',
        },
        {
            name: 'Business Analysis',
            desc: 'Understanding the needs of clients is critical from identifiying the business workflow to software solutions. I look at how clients are currently handling their work flow and help create updated workflows and possible enhancements to the software products I am responsible for.',
            icon: 'fas fa-chart-area',
        },
        {
            name: 'Cooking',
            desc: 'I have always had a passion to cook, it is one of those areas that helps me get away from the computer while still creating something amazing.',
            icon: 'fas fa-kitchen-set',
        },
        {
            name: 'Golf',
            desc: 'I love to golf, I am not great at golf.  One of the advantages to my travels allows me the opportunity to golf in different places.',
            icon: 'fas fa-golf-ball-tee',
        },
        {
            name: 'Travel',
            desc: 'Whether it is our RV, cruise ship, or flying abroad I love to travel with my beautiful wife and often our amazing German Shepard.',
            icon: 'fas fa-passport',
        },
    ];

    return (
        <section id='services' className={'section ' + (darkTheme ? 'bg-dark-2' : 'bg-light')}>
            <div className={'container ' + (classicHeader ? '' : 'px-lg-5')}>
                {/* Heading */}
                <div className='position-relative d-flex text-center mb-5'>
                    <h2
                        className={
                            'text-24  text-uppercase fw-600 w-100 mb-0 ' +
                            (darkTheme ? 'text-white-50  opacity-1' : 'text-light  opacity-4')
                        }
                    >
                        Services
                    </h2>
                    <p
                        className={
                            'text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 ' +
                            (darkTheme ? 'text-white' : 'text-dark')
                        }
                    >
                        What I Do?
                        <span className='heading-separator-line border-bottom border-3 border-primary d-block mx-auto' />
                    </p>
                </div>
                {/* Heading end*/}
                {/* content start */}
                <div className='row'>
                    <div className='col-lg-11 mx-auto'>
                        <div className='row'>
                            {services.length > 0 &&
                                services.map((service, index) => (
                                    <div className='col-md-6' key={index}>
                                        <div className='featured-box style-3 mb-5'>
                                            <div
                                                className={
                                                    'featured-box-icon text-primary  shadow-sm rounded ' +
                                                    (darkTheme ? 'bg-dark-1' : 'bg-white')
                                                }
                                            >
                                                <i className={service.icon} />
                                            </div>
                                            <h3 className={darkTheme ? 'text-white' : ''}>{service.name}</h3>
                                            <p className={'mb-0 ' + (darkTheme ? 'text-white-50' : '')}>
                                                {service.desc}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                {/* content end */}
            </div>
        </section>
    );
};

export default Services;
